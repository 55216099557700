import Icon from '@/app/ui/icons/Icon';
import { useEffect, useRef } from 'react';
import { Dict } from '@/app/types/Dict';
import anime from 'animejs/lib/anime';

const cards = [
  {
    title: 'Burning mechanism',
    className: 'rotate-[2.85deg]'
  },
  {
    title: 'Deflationary',
    className: 'rotate-[-1.697deg]'
  },
  {
    title: 'Fixed supply',
    className: 'rotate-[2.064deg] -top-[15px]'
  },
  {
    title: 'Strong economy',
    className: 'rotate-[-1.36deg] -top-[2px]'
  }
];

export default function DesktopCards() {
  const cardsRef = useRef<HTMLDivElement | null>(null);
  const cardsContainer = useRef<HTMLDivElement | null>(null);

  let cardsAnimation: Dict;

  useEffect(() => {
    const handleAnimation = (entry: Dict) => {
      if (entry.isIntersecting) {
        cardsAnimation.play();
      } else {
        cardsAnimation.pause();
        cardsAnimation.seek(0);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        handleAnimation(entry);
      },
      {
        root: null,
        rootMargin: '50px',
        threshold: 0.5
      }
    );

    if (cardsRef.current) {
      observer.observe(cardsRef.current);
      cardsAnimation = anime({
        targets: cardsContainer.current,
        translateY: '0vh',
        duration: 500,
        autoplay: false,
        easing: 'easeInElastic(3, 2)',
        delay: 0
      });
    }

    return () => {
      if (cardsRef.current) {
        observer.unobserve(cardsRef.current);
      }
    };
  }, []);

  return (
    <div ref={cardsRef}>
      <div
        ref={cardsContainer}
        className="flex justify-center gap-[50px]"
        style={{ transform: 'translateY(60vh)' }}
      >
        {cards.map((item) => (
          <div
            key={item.title}
            className={`relative flex h-[159px] w-[226px] items-center justify-center ${item.className}`}
          >
            <div className="absolute z-0">
              <Icon name="card" width={226} height={159} viewBox="0 0 225 163" />
            </div>
            <div className="absolute top-1 z-[1]">
              <Icon name="nail" width={39} height={40} />
            </div>
            <p className="quiroh-font relative z-[2] w-11/12 text-center text-[28px] font-bold text-baby-blue">
              {item.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
