'use client';

import SectionsWrapper from '@/app/ui/SectionsWrapper';
import Image from 'next/image';
import tvScreen from '@/app/ui/sections/find-your-way/images/tv_screen.webp';
import sdk from '@/app/ui/sections/find-your-way/images/sdk.webp';
import useWindowSize from '@/app/hooks/useWindowSize';
import { supercell } from '@/app/ui/font';
import { useEffect, useRef } from 'react';
import { useNavigation } from '@/app/contexts/Navigation';

export default function FindYourWaySection() {
  const ref = useRef<HTMLDivElement | null>(null);
  const roadMap = useRef<HTMLDivElement | null>(null);
  const { setRefs } = useNavigation();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (ref.current) {
      setRefs?.((prev) => ({
        ...prev,
        findway: ref.current,
        roadmapSection: roadMap.current,
      }));
    }
  }, [ref, setRefs]);

  return (
    <SectionsWrapper className="bg-find-your-way-background bg-cover bg-center pb-[52px] pt-[152px] lg:pb-[175px]">
      <div
        ref={ref}
        className="absolute left-0 top-0 h-[30px] w-full bg-find-your-way-top bg-cover bg-center"
      />
      <div className="container px-[33px] lg:px-0">
        <div className="mx-auto grid auto-cols-auto grid-cols-1 lg:grid-cols-2 lg:gap-y-[230px] ">
          <div className="row-start-2 lg:row-start-1">
            <div className="xl:h-[440px] relative flex h-[500px] w-full justify-center lg:h-[380px] 2xl:h-[567px]">
              <div className="blur-137 absolute z-0 h-[254px] w-[370px] rounded-full bg-electric-blue lg:h-full lg:w-full" />

              <video
                autoPlay
                muted
                loop
                playsInline
                className="xl:top-[-40px] absolute left-2/4 top-4 flex w-[99%] max-w-[365px] -translate-x-2/4 lg:top-[-85px] lg:h-[500px] lg:max-w-full 2xl:top-0"
              >
                <source src="/videos/tv_video.mp4" type="video/mp4" />
              </video>
              <Image
                src={tvScreen}
                width={!isMobile ? 806 : 370}
                height={!isMobile ? 550 : 254}
                alt="TV screen"
                className="absolute"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
            </div>
          </div>
          <div className="xl:ml-[141px] row-start-1 ml-0 flex w-full flex-col lg:col-start-2 lg:w-[400px] lg:justify-self-end 2xl:justify-self-start">
            <h1
              className={`${supercell.className} text-center text-[42px] leading-[115%] text-baby-blue lg:text-left lg:text-[63px]`}
            >
              <span className="block text-vivid-orange">Find your</span> way out
            </h1>
            <p className="quiroh-font mb-[31px] mt-[26px] text-center text-cool-mist lg:text-left lg:text-[24px]">
              Embark on an exciting adventure to collect treasures and coins
              while outsmarting ghosts to escape the haunted house. Can you
              solve the puzzles and find your way out before its too late?
            </p>
          </div>
          <div className="mb-[71px] pl-0 lg:mb-0 lg:pl-10">
            <h1
              className={`${supercell.className} text-center text-[28px] leading-[150%] text-baby-blue lg:text-left lg:text-[39px]`}
            >
              <span className="block text-[42px] text-vivid-orange lg:text-[63px]">
                Gamster SDK
              </span>
              Build, Play and Earn
            </h1>
            <p className="quiroh-font mt-[36px] w-full text-center text-cool-mist lg:mt-[45px] lg:text-left lg:text-[24px]">
              Introducing the Gamster SDK, a powerful Layer-2 toolset designed
              for entrepreneurs, developers, and gaming enthusiasts of all
              backgrounds. This innovative SDK allows you to dive into game
              development, even if you’re not a tech expert, enabling you to
              build immersive levels, design challenging scenarios, and create
              unique gaming experiences within the Gamster universe. Best of
              all, you can monetize your creations and earn from your
              developments, tapping into the rapidly growing Play-to-Earn
              industry.
            </p>
          </div>
          <div className="relative">
            <div className="xl:top-24 absolute left-2/4 z-0 h-2/4 w-2/4 -translate-x-1/4 rounded-full bg-electric-blue blur-[137px] lg:top-2" />
            <Image src={sdk} alt="SDK" className="relative z-10" />
          </div>
        </div>
      </div>
      <div ref={roadMap} className="absolute bottom-0 left-0 h-[50px]" />
      <div className="absolute bottom-0 left-0 h-[30px] w-full bg-find-your-way-bottom bg-cover bg-center" />
    </SectionsWrapper>
  );
}
